exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dom-index-js": () => import("./../../../src/pages/dom/index.js" /* webpackChunkName: "component---src-pages-dom-index-js" */),
  "component---src-pages-dom-kartuska-14-a-js": () => import("./../../../src/pages/dom/kartuska14a.js" /* webpackChunkName: "component---src-pages-dom-kartuska-14-a-js" */),
  "component---src-pages-dom-kartuska-14-b-js": () => import("./../../../src/pages/dom/kartuska14b.js" /* webpackChunkName: "component---src-pages-dom-kartuska-14-b-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inwestor-js": () => import("./../../../src/pages/inwestor.js" /* webpackChunkName: "component---src-pages-inwestor-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lokalizacja-js": () => import("./../../../src/pages/lokalizacja.js" /* webpackChunkName: "component---src-pages-lokalizacja-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

